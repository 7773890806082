<template>
    <div class="econ">  
        <breadcrumb icon="el-icon-s-grid"></breadcrumb>
        <div class="tabbox">
            <econ-nav cate="policy"></econ-nav> 
        </div>
        <router-view />
    </div>
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import EconNav from '@/components/cityreserch/EconNav.vue'
export default {
    name: 'VueElementTupaiIndex',
    data() {
        return {
            
        };
    },
    components: { Breadcrumb,EconNav},
    mounted() {
    },
    methods: {   
    },
};
</script>

<style lang="scss" scoped>
    .econ{
        width:98%;
        // height:calc(100vh - 80px);
     
        overflow: hidden;
        .tabbox{  
            padding-top:5px;
            width:98%;
            margin:0 auto;
        }
    }
    
</style>